<template>
  <div class="vuetify-popover-menu">
    <v-menu v-bind="menuVOptions">
      <template v-slot:activator="{ on, attrs }">
        <!-- badget & button-->
        <span :class="badgetClass">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>{{ icon }}</v-icon>
          </v-btn>
        </span>
      </template>

      <v-list dense>
        <!-- Items disponibles -->
        <template v-if="items.length > 0">
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            v-bind="item.params"
            @click="handleClickMenuItem(i)"
          >
            <v-list-item-icon v-if="item.icon">
              <v-icon :color="item.type">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.label }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <!-- Sin items -->
        <template v-else>
          <v-list-item @click="handleClickMenuItem(-1)">
            <v-list-item-content>
              <v-list-item-title>
                {{ labelEmpty }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'VuetifyPopoverMenu',
  props: {
    icon: {
      type: String,
      default: 'mdi-plus'
    },
    items: {
      type: Array,
      default() {
        return []
      },
      required: true
    },
    labelEmpty: {
      type: String,
      default: 'No hay elementos a mostrar'
    },
    /**
     * https://vuetifyjs.com/en/components/menus/#menus
     */
    menuVOptions: {
      default() {
        return {
          bottom: true,
          left: true,
          'content-class': 'vuetify-popover-menu-content'
        }
      },
      type: Object
    },
    showBadget: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Devuelve la clase "badget" si cumple las condiciones
     *
     * @return {String}
     */
    badgetClass() {
      return this.items.length > 0 && this.showBadget ? 'badget' : ''
    }
  },
  methods: {
    /**
     * Handle click on item
     *
     * @param {Number} index - index inside list items
     */
    handleClickMenuItem(index) {
      this.$emit('onClickMenuItem', index)
    }
  }
}
</script>
<style lang="scss">
.vuetify-popover-menu {
  & > .badget {
    position: relative;
    &::after {
      position: absolute;
      top: -5px;
      left: 10px;
      display: block;
      content: '';
      width: 8px;
      height: 8px;
      background: #f44336;
      border-radius: 50%;
    }
  }
}
.vuetify-popover-menu-content {
  .v-list {
    .v-list-item {
      .v-list-item__icon {
        margin-right: 6px;
      }
    }
  }
}
@media (max-width: 600px) {
  .vuetify-popover-menu {
    & > .badget {
      &::after {
        top: -2px;
        left: 4px;
        width: 6px;
        height: 6px;
      }
    }
  }
}
</style>
