// Constantes
import { ADDONS, MENUS_TYPES } from '@/constants'
// Components
import FormButtons from '@/components/ui/FormButtons'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import VuetifyDialog from '@/components/ui/VuetifyDialog'
import PreviewImageUploader from '@/components/ui/PreviewImageUploader'
import DishRationOptions from '@/components/elements/dishes/DishRationOptions'
import DishRationInputs from '@/components/elements/dishes/DishRationInputs'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
import addonsMixin from '@/mixins/addonsMixin'
import intercomMixin from '@/mixins/intercomMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Vuex
import { mapGetters } from 'vuex'
// Services
import { createDish, updateDishById, getDishById, deleteDishById } from '@/services/dish'
import { getParentCategoryByChildId } from '@/services/category'
import { getDefaultRations } from '@/services/ration'
// Utils
import { get, isNil, cloneDeep } from 'lodash'
import { stringToNumber } from '@/utils'
// Filters
import { sanitizeHtmlContent } from '@/filters'

// Different options to prices
const priceOptions = [
  {
    id: 0,
    label: 'Sin precio'
  },
  {
    id: 1,
    label: 'Precio único'
  },
  {
    id: 2,
    label: 'Por ración'
  }
]

export default {
  name: 'DishForm',
  components: {
    FormButtons,
    PreviewImageUploader,
    VuetifyDialog,
    DishRationInputs,
    VuetifyContentLoading
  },
  mixins: [formMixin, uiMixin, validationMixin, addonsMixin, intercomMixin],
  props: {
    id: {
      type: String,
      default: null
    },
    categoryId: {
      type: String,
      default: null
    },
    // Variables del componente padre "VuetifyTabs"
    index: {
      // Indice que ocupo dentro del componente
      type: Number,
      default: 0
    },
    itemsData: {
      // Datos que se comparten entre pestañas del componente
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      // Form fields
      formFields: {
        name: null,
        description: null,
        disabled: false,
        image: null,
        imageThumbnail: null,
        prices: null
      },
      formFieldsValidations: {
        name: {
          required: 'Campo obligatorio'
        },
        prices: {
          noEmpty: 'Debes indicar un precio'
        }
      },
      // Data Dish and Category
      dishData: null,
      currentId: this.id,
      isMenu: false,
      isTAD: false,
      menuData: null,
      // PreviewImageUploader
      maxSizeImage: {
        width: 800,
        height: 600
      },
      // Rations (Dialog open or close)
      rationsOptionsDialog: false,
      // Price
      priceTypes: priceOptions,
      currentPriceTypes: 0,
      // Others
      processingRequest: true
    }
  },
  computed: {
    ...mapGetters('place', ['placeData', 'areThereAdditionalLanguages']),
    ...mapGetters('meta', ['rationsData']),
    ...mapGetters('intercom', ['intercomData']),
    /**
     * ¿Estamos bajo la ruta de onBoarding?
     *
     * @return {Boolean}
     */
    weAreUnderOnboardingRoute() {
      return this.$route.path.indexOf('/onboarding/') > -1
    },
    /**
     * Get "rationsData" like Object
     *
     * @return {Object}
     */
    rationDataObject() {
      return this.rationsData.reduce((sumRations, ration) => {
        const { id, ...last } = ration
        sumRations[id] = last
        return sumRations
      }, {})
    },
    /**
     * Get the labels to show in the formulary
     *
     * @return {string} - label in save button
     */
    labelSaveButton() {
      const menuType = get(this.menuData, 'type', MENUS_TYPES.place.value)
      return this.areThereAdditionalLanguages &&
        isNil(this.currentId) &&
        menuType === MENUS_TYPES.place.value
        ? 'Crear'
        : 'Guardar'
    },
    /**
     * El producto se encuentra en más de una categoría
     *
     * @return {Boolean}
     */
    isInMoreThatOneCategory() {
      return (
        this.dishData &&
        Array.isArray(this.dishData.categories) &&
        this.dishData.categories.length > 1
      )
    },
    /**
     * Get the component to show the rations options
     */
    rationsOptions() {
      return DishRationOptions
    },
    /**
     * Get the current rations selected by user
     */
    currentRationOptionsSelected() {
      return {
        selectedItems: this.formFields.prices || []
      }
    },
    /**
     * Get current rations inputs to show
     * (vue validation model)
     */
    priceInputs() {
      return this.formFields.prices || []
    },
    /**
     * Path where the image will be saved
     *
     * @return {string}
     */
    storagePath() {
      return `${this.placeData.id}/dishes`
    },
    /**
     * Campos que son editables en el formulario
     *
     * @return {array | boolean} - listado de los campos editables
     */
    editableFields() {
      const owner = get(this.dishData, 'owner', null)
      const editabled = get(this.dishData, 'editabled', false)
      const fieldsCustomItem = get(this.dishData, 'fieldsCustomItem', [])

      if (!isNil(owner)) {
        return editabled ? fieldsCustomItem : []
      }

      return true // todo es editable
    },
    /**
     * El formulario es editable?
     *
     * @return {boolean}
     */
    formIsEditable() {
      return (
        (Array.isArray(this.editableFields) && this.editableFields.length > 0) ||
        this.editableFields === true
      )
    },
    /**
     * El producto fue creada por una marca?
     *
     * @return {boolean}
     */
    isBrandDish() {
      return !isNil(get(this.dishData, 'owner', null))
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * When the user must click on cancel button
     */
    handleCancelButton() {
      this.hideDialog()
    },
    /**
     * When the user must click on delete button
     */
    async handleDeleteButton() {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          // Loading
          this.formProcessing = true
          // Deleting...
          await this.deleteDish()
          this.hideDialog()
        },
        actionButtonText: 'Borrar',
        text: '¿Desea borrar el producto?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Show rations dialog
     */
    handleShowRationsDialog() {
      if (this.currentPriceTypes === 2) {
        this.rationsOptionsDialog = true
      }
    },
    /**
     * Remove an ration inputs items from this.formFields.prices
     *
     * @param {Object} item - item to remove
     */
    handleRemoveRationsInputs(item) {
      this.formFields.prices = this.formFields.prices.filter((ration) => {
        return item.id !== ration.id
      })
    },
    /**
     * Handle event from component loaded
     *
     * @param {Array} rations - rations ids
     */
    handleRationsComponent(rations) {
      const formFieldRations = []

      rations.forEach((rationSelected, index) => {
        const rationIndexInFormFields = this.formFields.prices
          ? this.formFields.prices.findIndex((formFieldRation) => {
              return formFieldRation.id === rationSelected
            })
          : -1

        if (rationIndexInFormFields === -1) {
          formFieldRations.push({
            id: rationSelected,
            name: this.rationDataObject[rationSelected].name,
            order: index,
            price: stringToNumber(this.rationDataObject[rationSelected].price)
          })
        } else {
          formFieldRations.push(this.formFields.prices[rationIndexInFormFields])
        }
      })

      this.formFields.prices = formFieldRations
    },
    /**
     * Handle event from rations dialog "accept button"
     */
    handleRationsDialogAcceptButton() {
      this.rationsOptionsDialog = false
    },
    /**
     * Handle event when the price type changes
     *
     * @param {Number} type - price type
     */
    handleCurrentPriceTypes(type) {
      if (type === 0) {
        // Sin precio
        this.formFields.prices = null
      } else if (type === 1) {
        // Precio único
        this.formFields.prices = this.parsePricesObjectToArray(getDefaultRations())
      } else if (type === 2) {
        // Por tipo de tapa
        this.formFields.prices = null
      }
    },
    /**
     * Manejador del evento, cuando se suben
     * nuevos ficheros (imágenes)
     *
     * @param {array} files - Ficheros subidos
     */
    handleImagesUploaded(files) {
      if (files.length > 0) {
        // Establecemos los valores del formulario
        // con las imágenes subidas
        files.forEach((file) => {
          if (file.thumbnail) {
            this.formFields.imageThumbnail = file.url
          } else {
            this.formFields.image = file.url
          }
        })
      } else {
        this.formFields.image = null
        this.formFields.imageThumbnail = null
      }
    },
    /**
     * Esta función es llamada desde el padre (VuetifyTabs)
     * cuando el usuario pulsa sobre alguno de las pestañas
     *
     * @param {Number} tab - pestaña donde deseamos movernos
     */
    async fnToChangeTab(tab) {
      let result = false

      try {
        if (isNil(this.currentId)) {
          // Create categorie
          result = await this.onSubmit()
        } else {
          // Update categorie
          result = this.actionSubmit()
        }

        if (result) {
          this.$parent.$parent.$parent.$emit('onChangeTab', tab)
        }
      } catch (error) {
        // show error
        this.handleError(error.message)
      } finally {
        this.formProcessing = false
      }
    },
    /**
     * Cargamos todos los datos necesarios para el formulario
     */
    async getEveryNeededData() {
      try {
        // Loading
        this.processingRequest = true

        // Categoría padre
        this.menuData = await getParentCategoryByChildId(this.categoryId)
        const menuType = get(this.menuData, 'type', MENUS_TYPES.place.value)
        const menuPrice = get(this.menuData, 'price', null)

        // Menu - sin precio en los productos
        if (menuType === MENUS_TYPES.place.value && menuPrice) {
          this.isMenu = true
          this.currentPriceTypes = 0
          this.priceTypes = priceOptions.filter((option) => option.id === 0)
        } else if (menuType === MENUS_TYPES.takeAway.value) {
          // TAD - no se permiten productos sin precio
          this.isTAD = true
          this.currentPriceTypes = 1
          this.priceTypes = priceOptions.filter((option) => option.id !== 0)
        }

        // Onboarding - productos con "precio único"
        if (!this.isMenu && this.weAreUnderOnboardingRoute) {
          this.currentPriceTypes = 1
          this.priceTypes = priceOptions.filter((option) => option.id === 1)
        }

        // "Rellenamos" campo "prices" para que aparezca el input
        this.handleCurrentPriceTypes(this.currentPriceTypes)

        if (!isNil(this.currentId)) {
          // Datos del producto
          this.dishData = await this.getDishData(this.currentId)
          // Para que los datos estén disponibles para el resto de pestañas
          // del formulario, lanzamos el evento
          this.changeItemsData({
            data: this.dishData
          })
          // Seteamos datos del formulario
          this.setFormFieldsValues(this.dishData)
        }
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Get the data of current dish
     *
     * @param {String} id - UID dish
     * @return {Object}
     */
    async getDishData(id) {
      const dishData = await getDishById(id)
      return dishData
    },
    /**
     * Establece los datos del producto y sus precios
     * en los campos del formulario
     *
     * @param {object} data - datos del plato
     */
    async setFormFieldsValues(data = {}) {
      const prices = get(data, `prices[${this.categoryId}]`, null)

      // Seteamos campos del formulario
      this.formFields = {
        name: get(data, 'name', this.formFields.name),
        description: sanitizeHtmlContent(get(data, 'description', this.formFields.description), []),
        disabled: get(data, 'disabled', this.formFields.disabled),
        image: get(data, 'image', this.formFields.image),
        imageThumbnail: get(data, 'imageThumbnail', this.formFields.imageThumbnail),
        prices: this.parsePricesObjectToArray(prices)
      }

      // price type
      if (isNil(prices)) {
        this.currentPriceTypes = 0 // Sin precio
      } else if (!isNil(prices) && prices[0]) {
        this.currentPriceTypes = 1 // Precio único
      } else if (!isNil(prices) && !prices[0]) {
        this.currentPriceTypes = 2 // Precio tipo ración
      }
    },
    /**
     * Dado un campo del formulario, comprobamos si este puede
     * ser editado
     *
     * @param {string} name - nombre del campo
     * @return {boolean} - es editable?
     */
    isEditable(name = null) {
      return Array.isArray(this.editableFields)
        ? this.editableFields.indexOf(name) > -1
        : this.editableFields
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      if (isNil(this.currentId)) {
        // Creamos producto
        await this.createProduct()
      } else {
        // Actualizamos plato
        await this.updateProduct()
      }
      // Evento general para que cualquier lista
      // a la escucha, recargue su listado
      this.$root.$emit('reload-list')
    },
    /**
     * Procesos para la creación de nuevo producto
     */
    async createProduct() {
      // Configuraciones del establecimiento
      const placeConfig = get(this.placeAddonsSetupByUser, ADDONS.place, {})
      // Datos del plato
      const { description, ...others } = this.formFields
      // Obtenemos raciones de "DishRationInputs"
      const dishRationInputsItems = get(this.$refs, 'dishRationInputs.items', null)
      const prices =
        !this.isMenu && !isNil(dishRationInputsItems) ? cloneDeep(dishRationInputsItems) : null

      // Parse prices to save in DB
      const dataToSave = {
        ...others,
        description: sanitizeHtmlContent(description, []),
        prices: !isNil(prices) ? this.parsePricesArrayToObject(prices) : null
      }

      // Creamos plato
      const { dish } = await createDish(
        {
          ...dataToSave,
          categories: [this.categoryId]
        },
        null,
        {
          additionalLanguages: placeConfig.additionalLanguages,
          defaultLanguage: placeConfig.defaultLanguage
        }
      )

      // Establecemos el nuevo ID del plato
      this.currentId = dish.id
      // Establecemos los nuevos datos del plato
      this.dishData = dish

      // Modificamos "itemsData" del componente padre (VuetifyTabs)
      this.changeItemsData({
        data: this.dishData
      })

      // Intercom
      const totalProducts = get(this.intercomData, 'products', 0)
      this.intercomUpdate({
        bakarta_productos: totalProducts + 1
      })

      // show info
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })

      // Emitimos evento general
      this.$root.$emit('completed-product-form')
    },
    /**
     * Procesos para la actualización de un producto
     */
    async updateProduct() {
      // Configuraciones del establecimiento
      const placeConfig = get(this.placeAddonsSetupByUser, ADDONS.place, {})
      // Datos del formulario
      const { description, ...others } = this.formFields
      // Obtenemos raciones de "DishRationInputs"
      const dishRationInputsItems = get(this.$refs, 'dishRationInputs.items', null)
      const prices =
        !this.isMenu && !isNil(dishRationInputsItems) ? cloneDeep(dishRationInputsItems) : null
      // Preparamos los datos para salvar
      const dataToSave = {
        ...others,
        description: sanitizeHtmlContent(description, []),
        prices: !isNil(prices) ? this.parsePricesArrayToObject(prices) : null
      }

      // Actualizamos producto
      await updateDishById(
        {
          id: this.currentId,
          // Filtramos los campos editables
          ...(!Array.isArray(this.editableFields)
            ? dataToSave
            : this.editableFields.reduce((accFields, field) => {
                if (!isNil(dataToSave[field])) {
                  accFields[field] = dataToSave[field]
                }
                return accFields
              }, {}))
        },
        {
          additionalLanguages: placeConfig.additionalLanguages,
          defaultLanguage: placeConfig.defaultLanguage
        }
      )

      // Modificamos "itemsData" del componente padre (VuetifyTabs)
      this.changeItemsData({
        data: { ...this.dishData, ...dataToSave }
      })

      // show info
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    },
    /**
     * Different actions to delete the categories
     * and every associated to it
     */
    async deleteDish() {
      try {
        // Solo por seguridad.
        // Un establecimiento, no puede borrar los datos
        // de un producto creada por la marca
        if (this.isBrandDish) return

        // Finalmente eliminamos el registro
        const { ok } = await deleteDishById(this.dishData.id, this.categoryId)

        if (!ok) {
          throw new Error('Hubo un error al intentar eliminar el plato.')
        }

        // Intercom
        const totalProducts = get(this.intercomData, 'products', 0)
        this.intercomUpdate({
          bakarta_productos: totalProducts > 0 ? totalProducts - 1 : 0
        })

        // Close modal
        this.hideDialog()
      } catch (error) {
        // show error
        this.handleError(error.message)
      } finally {
        // Evento general para que cualquier lista
        // a la escucha, recargue su listado
        this.$root.$emit('reload-list')
      }
    },
    /**
     * Parse "prices" variable to validate with Vue validate
     *
     * @param {Object} prices - prices value
     * @return {Array} - array to work with Vue validate
     */
    parsePricesObjectToArray(prices) {
      if (isNil(prices)) {
        return prices
      }

      let parsePrices = []

      if (typeof prices === 'object' && Object.keys(prices).length) {
        parsePrices = Object.entries(prices).reduce((sumRations, ration, index) => {
          sumRations.push({
            id: ration[0],
            name:
              this.rationDataObject &&
              this.rationDataObject[ration[0]] &&
              this.rationDataObject[ration[0]].name
                ? this.rationDataObject[ration[0]].name
                : '',
            order: typeof ration[1].order !== 'undefined' ? ration[1].order : index,
            price: stringToNumber(ration[1].price)
          })
          return sumRations
        }, parsePrices)
      }
      return parsePrices.sort((a, b) => {
        return a.order - b.order
      })
    },
    /**
     * Parse "prices" to save in the right format
     *
     * @param {Array} prices - prices prices
     * @return {Object} - array to save in database
     */
    parsePricesArrayToObject(prices) {
      const everyPrice = this.dishData && this.dishData.prices ? this.dishData.prices : {}
      const parsePrices =
        !isNil(prices) && Array.isArray(prices)
          ? prices.reduce((sumRations, ration, index) => {
              sumRations[ration.id] = {
                order: index,
                price: stringToNumber(ration.price)
              }
              return sumRations
            }, {})
          : null

      everyPrice[this.categoryId] =
        parsePrices !== null && Object.keys(parsePrices).length > 0 ? parsePrices : null

      return everyPrice
    },
    /**
     * Hide dialog
     */
    hideDialog() {
      this.modifyAppDialog({
        visible: false
      })
    },
    /**
     * Modificamos el atributo "itemsData" del componente padre
     *
     * @param {Object} data - datos a emitir
     */
    changeItemsData(data) {
      this.$parent.$parent.$parent.$emit('onChangeItemsData', this.index, data)
    }
  },
  // Validations with Vuelidate
  validations() {
    const that = this

    const rules = {
      formFields: {
        name: {
          required
        },
        description: {},
        disabled: {},
        image: {},
        imageThumbnail: {},
        prices: {
          noEmpty: (value) => {
            return that.currentPriceTypes === 0
              ? true
              : !isNil(value) && value.every((v) => !isNil(v.price) && v.price > 0)
          }
        }
      }
    }

    return rules
  }
}
