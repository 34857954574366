<template>
  <div class="advanced-management-clone-form">
    <v-container>
      <!-- Loading -->
      <VuetifyContentLoading :loading="processingRequest" />
      <!-- Content -->
      <v-form v-if="!processingRequest" novalidate @submit.prevent="onSubmit">
        <v-row>
          <v-col cols="12">
            <p>
              Selecciona la carta y la categoría desde la cual deseas clonar los productos asociada
              a esta.
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <!-- Selector de cartas -->
            <v-select
              v-model="$v.formFields.parentCategoryId.$model"
              :items="parentCategoriesData"
              item-value="id"
              item-text="name"
              label="Seleccione una carta o menú"
              outlined
              dense
              color="grey"
              :hide-details="!checkFieldErrors('parentCategoryId').length"
              :error-messages="checkFieldErrors('parentCategoryId')"
              @input="touchField('parentCategoryId')"
              @blur="touchField('parentCategoryId')"
            />
          </v-col>
          <v-col v-if="categoriesData.length" cols="12">
            <!-- Selector de categories -->
            <v-select
              v-model="$v.formFields.categoryId.$model"
              :items="categoriesData"
              item-value="id"
              item-text="name"
              label="Seleccione una categoría"
              outlined
              dense
              color="grey"
              :hide-details="!checkFieldErrors('categoryId').length"
              :error-messages="checkFieldErrors('categoryId')"
              @input="touchField('categoryId')"
              @blur="touchField('categoryId')"
            />
          </v-col>
        </v-row>
        <!-- action buttons -->
        <FormButtons
          accept-button-text="Selecionar"
          :accept-button-loading="formProcessing"
          @onClickCancelButton="handleCancelButton"
        />
      </v-form>
    </v-container>
  </div>
</template>

<script>
// Constants
import { ACTION_TYPE } from '../../constants'
// Components
import FormButtons from '@/components/ui/FormButtons'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Services
import { getCategoriesByParentId, getEveryMenusByPlaceId } from '@/services/category'
// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'AdvancedManagementCloneForm',
  components: { FormButtons, VuetifyContentLoading },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    targetCategory: {
      type: String,
      default: null,
      required: true
    },
    type: {
      type: String,
      default: ACTION_TYPE.category,
      required: true
    }
  },
  data() {
    return {
      // Form
      formFields: {
        parentCategoryId: null,
        categoryId: null
      },
      formFieldsValidations: {
        parentCategoryId: {
          required: 'Debe seleccionar una carta o menú'
        },
        categoryId: {
          required: 'Debe seleccionar la categoría a clonar'
        }
      },
      // Others
      categoriesData: [],
      parentCategoriesData: [],
      processingRequest: true
    }
  },
  computed: {
    ...mapGetters('place', ['placeData'])
  },
  watch: {
    'formFields.parentCategoryId': async function (value) {
      await this.setCategoriesData(value)
    }
  },
  async mounted() {
    await this.setParentCategoriesData()
  },
  methods: {
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * When the user must click on cancel button
     */
    handleCancelButton() {
      this.hideDialog()
    },
    /**
     * Obtiene todas las cartas del establecimiento
     *
     * @return {Array} - categories (menus)
     */
    async setParentCategoriesData() {
      try {
        // Loading
        this.processingRequest = true
        // Get categories
        const parentCategories = await getEveryMenusByPlaceId(this.placeData.id)
        this.parentCategoriesData = parentCategories.map((category) => {
          return {
            id: category.id,
            name: category.name
          }
        })
      } catch (error) {
        // show error
        this.handleError(error.message)
        this.hideDialog()
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Obtiene todas las categorías de una carta o menú
     *
     * @param {string} id - UID category
     * @return {Array} - categories
     */
    async setCategoriesData(id) {
      try {
        // Loading
        this.processingRequest = true
        // Get categories
        const categories = await getCategoriesByParentId(id)
        this.categoriesData = categories.map((category) => {
          return {
            id: category.id,
            name: category.name
          }
        })
      } catch (error) {
        // show error
        this.handleError(error.message)
        this.hideDialog()
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Move to products form
      this.routerPushTo({
        name: 'advancedManagementProductsCloningForm',
        params: {
          targetCategory: this.targetCategory,
          sourceCategory: this.formFields.categoryId,
          type: this.type
        }
      })
      // Hide dialog
      this.hideDialog()
    },
    /**
     * Hide dialog
     */
    hideDialog() {
      this.modifyAppDialog({
        visible: false
      })
    }
  },
  // Validations with Vuelidate
  validations: {
    formFields: {
      parentCategoryId: {
        required
      },
      categoryId: {
        required
      }
    }
  }
}
</script>
