<template>
  <div class="dishes-list-item" @click="handleClickName(item.id)">
    <span class="item">
      <span class="item-label">
        {{ item.name }}
        <span v-if="item.disabled" class="error--text">(N/D)</span>
      </span>
      <span class="item-about">
        <!-- Image -->
        <v-icon v-if="item.image" class="item-icon secondary--text">mdi-camera</v-icon>
        <!-- Description -->
        <v-icon v-if="item.description" class="item-icon secondary--text"
          >mdi-text-box-outline</v-icon
        >
        <!-- Alérgenos -->
        <v-icon v-if="item.allergens && item.allergens.length" class="item-icon secondary--text"
          >mdi-alpha-a-circle-outline</v-icon
        >
      </span>
    </span>
    <span class="dishes-list-item__prices">
      <DishesListPrices :category="category" :item="item" />
    </span>
  </div>
</template>

<script>
// Components
import DishesListPrices from '@/components/elements/dishes/DishesListPrices'

export default {
  name: 'DishesListItem',
  components: { DishesListPrices },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    category: {
      type: String,
      default: null
    }
  },
  methods: {
    /**
     * Handle click on name
     *
     * @param {string} id - item id
     */
    handleClickName(id) {
      this.$emit('onClickName', id)
    }
  }
}
</script>

<style lang="scss" scoped>
.dishes-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  .item {
    font-size: 0.875rem;
    line-height: 0.875rem;
    width: 60%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .item-label {
      margin-bottom: 0.25rem;
    }
    .item-about {
      .item-icon {
        font-size: 1.15rem;
        margin-right: 0.15rem;
      }
    }
  }
  .dishes-list-item__prices {
    width: 40%;
  }
}
</style>
