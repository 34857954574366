<template>
  <div class="dishes-list-prices">
    <template v-if="getPrices">
      <div v-for="(ration, index) in getPrices" :key="index" class="price-info">
        <span v-if="ration.name" class="name">{{ ration.name }}:</span>
        <span class="value">
          <CurrencyPosition :price="ration.price" />
        </span>
      </div>
    </template>
  </div>
</template>

<script>
// Components
import CurrencyPosition from '@/components/ui/CurrencyPosition'
// Vuex
import { mapGetters } from 'vuex'
// Utils
import { get } from 'lodash'

export default {
  name: 'DishesListPrices',
  components: { CurrencyPosition },
  props: {
    category: {
      type: String,
      default: null
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters('meta', ['rationsData']),
    /**
     * Get "rationsData" like Object
     *
     * @return {Object}
     */
    getRationDataObject() {
      return this.rationsData.reduce((sumRations, ration) => {
        const { id, ...last } = ration
        sumRations[id] = last
        return sumRations
      }, {})
    },
    /**
     * Return the array prices to draw it
     *
     * @return {Array} - array prices
     */
    getPrices() {
      const itemPrices = get(this.item, `prices[${this.category}]`, null)

      if (itemPrices === null) {
        return null
      }

      const prices = Object.entries(itemPrices).reduce((sumPrices, price) => {
        sumPrices.push({
          name:
            this.getRationDataObject[price[0]] && this.getRationDataObject[price[0]].name
              ? this.getRationDataObject[price[0]].name
              : null,
          order: price[1].order,
          price: price[1].price
        })
        return sumPrices
      }, [])

      return prices.sort((a, b) => {
        return a.order - b.order
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.dishes-list-prices {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 0.85rem;
  line-height: 0.85rem;
  .price-info {
    margin: 0.25rem 0;
    text-align: right;
    .name {
      padding-right: 0.5rem;
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
