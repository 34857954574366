// Components
import FormButtons from '@/components/ui/FormButtons'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
// Vuex
import { mapGetters } from 'vuex'
// Services
import {
  getModifierById,
  deleteModifierById,
  deleteChildModifierById,
  saveModifier
} from '@/services/modifier'
import { isNil } from 'lodash'

export default {
  name: 'DishModifiersCreateForm',
  components: { FormButtons, VuetifyContentLoading },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    // Identificador del modificador a cambiar
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      // Form fields
      formFields: {
        childs: [],
        max: -1, // Número máximo de elementos a seleccionar
        min: 0, // Número mínimo de elementos a seleccionar
        name: null
      },
      formFieldsValidations: {
        childs: {
          name: {
            required: 'Campo obligatorio'
          },
          price: {}
        },
        name: {
          required: 'Campo obligatorio'
        },
        min: {
          moreThanMax: 'No puede ser mayor al máximo'
        },
        max: {
          lessThanMin: 'No puede ser menor al mínimo'
        }
      },
      // Others
      defaultChild: {
        name: null,
        price: 0
      },
      processingRequest: true
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    /**
     * Etiqueta para mostrar en el botón de Guardado
     *
     * @return {string} - label in save button
     */
    labelSaveButton() {
      return !this.id ? 'Crear' : 'Modificar'
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * Cuando pulsamos sobre el botón de añadir otro child
     */
    handleAddChildButton() {
      this.formFields.childs.push({ ...this.defaultChild })
    },
    /**
     * Cuando pulsamos sobre el botón de cancelar
     */
    handleCancelButton() {
      this.$parent.changeComponent('relations')
    },
    /**
     * Cuando pulsamos sobre el botón de eliminar
     */
    handleDeleteButton() {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          this.formProcessing = true
          try {
            // Lo eliminamos de BD
            const { ok } = await deleteModifierById(this.id)
            if (!ok) {
              throw new Error('Hubo un error al intentar borrar el modificador.')
            }
            // Información resultados acción de salvado
            this.modifyAppAlert({
              text: 'Modificador eliminado correctamente',
              visible: true
            })
            // Volvemos a las relaciones del producto
            this.$parent.changeComponent('relations')
          } catch (error) {
            this.handleError(error.message)
          } finally {
            this.formProcessing = false
          }
        },
        actionButtonText: 'Borrar',
        text: '¿Esta seguro que desea eliminar el modificador?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Cuando pulsamos sobre el botón eliminiar de un child
     *
     * @param {Number} index - indice a eliminar del array
     */
    handleDelChild(index) {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          this.formProcessing = true
          try {
            // Lo eliminamos de BD
            if (this.formFields.childs[index].id) {
              const { ok } = await deleteChildModifierById(
                this.formFields.childs[index].id,
                this.id
              )
              if (!ok) {
                throw new Error('Hubo un error en el intento de borrado.')
              }
            }
            // Lo eliminamos de la lista
            this.formFields.childs.splice(index, 1)
          } catch (error) {
            this.handleError(error.message)
          } finally {
            this.formProcessing = false
          }
        },
        actionButtonText: 'Borrar',
        text: '¿Esta seguro que desea eliminar el elemento indicado?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Array de elementos a seleccionar para valores mínimos y máximos
     *
     * @param {boolean} zeroIncluded - ¿Se incluye el valor 0 en las selecciones?
     */
    childOptions(zeroIncluded = true) {
      const childs = this.formFields.childs.map((child, index) => {
        return index + 1
      })
      return [...(zeroIncluded ? [0] : []), ...childs]
    },
    /**
     * Cargamos todo lo necesario para el componente
     */
    async getEveryNeededData() {
      try {
        // Establecemos datos del formulario
        if (isNil(this.id)) {
          this.setFormFieldsValues()
        } else {
          const modifierData = await getModifierById(this.id)
          this.setFormFieldsValues(modifierData)
        }
      } catch (error) {
        // Mostramos error
        this.handleError(error.message)
        // Volvemos a las relaciones del producto
        this.$parent.changeComponent('relations')
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Establecemos los datos del formulario
     *
     * @param {object} data - datos del formulario
     */
    setFormFieldsValues(data = null) {
      if (isNil(data)) {
        // Incluimos un primer elemento por defecto
        this.formFields.childs.push({
          ...this.defaultChild
        })
      } else {
        // Establecemos los datos del formulario
        this.formFields.max = data.max || this.formFields.max
        this.formFields.min = data.min || this.formFields.min
        this.formFields.name = data.name || this.formFields.name
        this.formFields.childs = data.childs || this.formFields.childs
      }
    },
    /**
     * Lanzada cuando pasamos la validación del formulario
     */
    async afterSubmit() {
      // Datos a salvar
      const dataToSave = {
        modifier: {
          max: this.formFields.max,
          min: this.formFields.min,
          name: this.formFields.name
        },
        childs: this.formFields.childs
      }

      // incluimos identificadores
      if (this.id) {
        dataToSave.modifier.id = this.id
      } else {
        dataToSave.modifier.modelId = this.placeData.id
      }

      // Salvamos las modificaciones del modificador
      await saveModifier(dataToSave)

      // Información resultados acción de salvado
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
      // Volvemos a las relaciones del producto
      this.$parent.changeComponent('relations')
    }
  },
  // Validations with Vuelidate
  validations() {
    const rules = {
      formFields: {
        childs: {
          required,
          minLength: minLength(1),
          $each: {
            name: {
              required
            },
            price: {}
          }
        },
        max: {
          lessThanMin: (value, field) => {
            return !(field.min > value && value > -1)
          }
        },
        min: {
          moreThanMax: (value, field) => {
            return !(field.max < value && field.max > -1)
          }
        },
        name: {
          required
        }
      }
    }
    return rules
  }
}
