// Components
import DishModifiersRelationsForm from '../DishModifiersRelationsForm'
import DishModifiersListForm from '../DishModifiersListForm'
import DishModifiersCreateForm from '../DishModifiersCreateForm'
// Utils
import { get } from 'lodash'

export default {
  name: 'DishModifiersForm',
  props: {
    // Variables del componente padre "VuetifyTabs"
    index: {
      // Indice que ocupo dentro del componente
      type: Number,
      default: 0
    },
    itemsData: {
      // Datos que se comparten entre pestañas del componente
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      // Distintas secciones (componentes)
      components: {
        relations: DishModifiersRelationsForm, // Componente que carga la relación de los modificadores con el plato
        create: DishModifiersCreateForm, // Formulario de creación/editar nuevo componente
        list: DishModifiersListForm // Listado de modificadores disponibles
      },
      currentComponent: null,
      currentComponentProps: {}
    }
  },
  mounted() {
    this.getEveryNeededData()
  },
  methods: {
    /**
     * Cambiamos el componente activo en ese momento
     *
     * @param {String} id - id del componente
     * @param {Object} props - propiedades para pasar al component
     */
    changeComponent(id = 'relations', props = {}) {
      // Incluimos los datos (plato) de la primera pestaña (padre)
      const dish = get(this.itemsData, '0.data', {})

      this.currentComponent = this.components[id]
      this.currentComponentProps = { dish, ...props }
    },
    /**
     * Modificamos el atributo "itemsData" del componente padre
     *
     * @param {Object} modifiers - datos a emitir
     */
    updateItemsData(modifiers) {
      const { data, id, model } = get(this.itemsData, '0', {})
      this.$parent.$parent.$parent.$emit('onChangeItemsData', 0, {
        id,
        model,
        data: { ...data, modifiers }
      })
    },
    /**
     * Cargamos todo lo necesario para el componente
     */
    getEveryNeededData() {
      // Cargamos la vista por defecto
      this.changeComponent('relations')
    }
  }
}
