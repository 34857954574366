// Components
import CurrencyPosition from '@/components/ui/CurrencyPosition'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Vuex
import { mapGetters } from 'vuex'
// Services
import { getEveryRationsByPlaceId } from '@/services/ration'
// Utils
import { isNil } from 'lodash'

export default {
  name: 'DishRationOptions',
  components: { CurrencyPosition, VuetifyContentLoading },
  props: {
    // Items que viene seleccionados desde el inicio
    selectedItems: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      items: [], // Items que componen las opciones
      processingRequest: true,
      currentSelectedItems: [] // Items seleccionadas en el componente
    }
  },
  computed: {
    ...mapGetters('place', ['placeData'])
  },
  async mounted() {
    // Obtenemos los datos iniciales
    await this.getEveryNeededData()
  },
  watch: {
    selectedItems: {
      handler(newValue, oldValue) {
        const atLeastOneValueIsEmpty =
          (!isNil(newValue) && isNil(oldValue)) || (isNil(newValue) && !isNil(oldValue))
        const hasDifferentLength =
          Array.isArray(newValue) && Array.isArray(oldValue) && newValue.length !== oldValue.length
        const haveDifferentValues =
          Array.isArray(newValue) &&
          Array.isArray(oldValue) &&
          newValue.some((item) => {
            const indexOldValue = oldValue.findIndex((value) => {
              return value.id === item.id
            })

            return indexOldValue === -1
          })

        if (atLeastOneValueIsEmpty || hasDifferentLength || haveDifferentValues) {
          this.setCurrentSelectedItems()
        }
      },
      immediate: true
    },
    currentSelectedItems(value) {
      // Este evento es lanzado para todo aquel componente
      // que ha sido incluido bajo el component "VuetifyDialog"
      this.$emit('onEventComponent', value)
    }
  },
  methods: {
    /**
     * Obtenemos todos los datos necesarios para
     * inicializar el listado de categorías
     */
    async getEveryNeededData() {
      try {
        // Loading
        this.processingRequest = true
        // Raciones del (solo) del establecimiento
        this.items = await getEveryRationsByPlaceId(this.placeData.id)
      } catch (error) {
        this.modifyAppAlert({
          type: 'error',
          text: error.message,
          visible: true
        })
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Establecemos los items iniciales seleccionados
     */
    setCurrentSelectedItems() {
      this.currentSelectedItems = !isNil(this.selectedItems)
        ? this.selectedItems.map((ration) => {
            return ration.id
          })
        : []
    }
  }
}
