// Constants
import { MENUS_TYPES } from '@/constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import DishFormByTabs from '@/components/elements/dishes/DishFormByTabs'
import DishesListItem from '@/components/elements/dishes/DishesListItem'
import DishesListKeys from '@/components/elements/dishes/DishesListKeys'
import DraggableList from '@/components/ui/DraggableList'
import SectionInformationBox from '@/components/ui/SectionInformationBox'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import VuetifyPopoverMenu from '@/components/ui/VuetifyPopoverMenu'
// Mixins
import uiMixin from '@/mixins/uiMixin'
import addonsMixin from '@/mixins/addonsMixin'
// Services
import { getEveryDishesByCategoryId, updateDishById } from '@/services/dish'
import { getCategoryById, getParentCategoryByChildId } from '@/services/category'
import { mapState, mapGetters } from 'vuex'
// Utils
import { getPathImage } from '@/utils'
import { get, isNil } from 'lodash'
// Addons
import { ACTION_TYPE } from '@/addons/advancedManagement/constants'
import AdvancedManagementCloneForm from '@/addons/advancedManagement/components/elements/AdvancedManagementCloneForm'
import AddonsNeedUpgradeContent from '@/components/elements/addons/AddonsNeedUpgradeContent'

export default {
  name: 'DishesList',
  components: {
    CardContainer,
    DraggableList,
    DishesListItem,
    DishesListKeys,
    SectionInformationBox,
    VuetifyPopoverMenu,
    VuetifyContentLoading,
    VuetifyToolBar
  },
  mixins: [uiMixin, addonsMixin],
  data() {
    return {
      id: this.$route.params.id || null, // categoryId
      category: null,
      items: [],
      parentCategory: null,
      isMenu: false,
      menuData: null,
      processingRequest: true
    }
  },
  computed: {
    ...mapState('app', ['extraSmallDevice']),
    ...mapGetters('place', ['areThereAdditionalLanguages']),
    /**
     * Items del listado de acciones del "popoverMenu"
     */
    popoverMenuItems() {
      const items = []

      // Acción de creación
      if (this.showActionButtonToolBar) {
        items.push({
          label: 'Crear producto',
          itemFn: this.handleDishFormDialog,
          params: [
            {
              'data-cy': 'bar-add-action-button'
            }
          ]
        })
      }
      // Acción de clonación
      items.push({
        label: 'Clonar productos',
        itemFn: this.handleCloneProducts
      })

      // Acción de edición en lotes
      if (isNil(get(this.menuData, 'price', null))) {
        items.push({
          label: 'Edición en lotes',
          itemFn: this.handleModifyProductsPrices
        })
      }

      return items
    },
    /**
     * Get the options to show the screen when there are not items
     */
    sectionInformationOptions() {
      return {
        media: getPathImage('onboarding/dishes.svg'),
        title: 'Productos de la categoría',
        description: 'Aún no has creado ningún producto',
        buttonLabel: 'Crear producto ¡ahora!',
        showButton: this.showActionButtonToolBar
      }
    },
    /**
     * Ocultar o mostrar botón "acción" en navegación
     *
     * @return {Boolean}
     */
    showActionButtonToolBar() {
      return get(this.menuData, 'allowCreateProducts', true)
    },
    /**
     * Get the current title of current view
     *
     * @return {String} - title to show in toolbar
     */
    toolbarTitle() {
      return this.parentCategory ? `${this.parentCategory.name} / Productos` : 'Productos'
    },
    /**
     * La "toolBar" es sticky
     *
     * @return {Boolean}
     */
    toolBarIsSticky() {
      return this.extraSmallDevice
    }
  },
  async mounted() {
    // Capturamos evento
    this.$root.$on('reload-list', this.setItemsList)
    // Obtenemos los datos iniciales
    await this.getEveryNeededData()
  },
  destroyed() {
    // Paramos escucha
    this.$root.$off('reload-list')
  },
  methods: {
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * handle change order in items
     *
     * @param {Array} items - items ordered
     */
    async handleChangeOrder(items) {
      if (Array.isArray(items)) {
        try {
          await Promise.all(
            items.map(async (item, index) => {
              // Datos de la posible edición
              const owner = get(item, 'owner', null)
              const editabled = get(item, 'editabled', false)
              const fieldsCustomItem = get(item, 'fieldsCustomItem', [])
              // Es editable el campo "order"?
              const orderIsEditable = Boolean(
                isNil(owner) ||
                  (!isNil(owner) && editabled && fieldsCustomItem.indexOf('order') > -1)
              )

              // Actulizamos posición
              if (orderIsEditable) {
                await updateDishById({
                  id: item.id,
                  order: index
                })
              }
            })
          )
        } catch (error) {
          this.handleError(error.message)
        }
      }
    },
    /**
     * handle create or clone categories
     *
     * @param {Number} index - actions index
     */
    async handleClickMenuItem(index) {
      if (
        this.popoverMenuItems[index] &&
        typeof this.popoverMenuItems[index].itemFn === 'function'
      ) {
        this.popoverMenuItems[index].itemFn()
      }
    },
    /**
     * Abre un "dialog" para crear/editar un plato
     *
     * @param {string} id - UID category en base de datos
     */
    handleDishFormDialog(id = null) {
      const parentCategoryType = get(this.menuData, 'type', MENUS_TYPES.place.value)

      // Pestañas adicionales de configuración a mostrar en el formulario
      const tabs = [
        {
          id: 'basic'
        },
        {
          id: 'allergens'
        }
      ]

      // Pestaña modificadores
      if (parentCategoryType === MENUS_TYPES.takeAway.value) {
        tabs.push({
          id: 'modifiers'
        })
      }

      // Pestaña de traducciones
      if (this.areThereAdditionalLanguages && parentCategoryType !== MENUS_TYPES.takeAway.value) {
        tabs.push({
          id: 'translation'
        })
      }

      this.modifyAppDialog({
        title: isNil(id) ? 'Crear producto' : 'Editar producto',
        contentComponent: DishFormByTabs,
        contentComponentProps: {
          id: id || null,
          categoryId: this.id,
          tabs
        },
        hideActionButtons: true,
        visible: true
      })
    },
    /**
     * Abre un "dialog" para clonar una categoría con sus productos
     * AdvancedManagement (addon)
     */
    handleCloneProducts() {
      // Mostramos la modal de clonación
      // o al mensaje de "necesitas actualizar"
      if (this.hasAdvancedManagementAddon) {
        this.modifyAppDialog({
          title: 'Clonar productos de categoría',
          contentComponent: AdvancedManagementCloneForm,
          contentComponentProps: {
            targetCategory: this.id,
            type: ACTION_TYPE.products
          },
          hideActionButtons: true,
          visible: true
        })
      } else {
        this.modifyAppDialog({
          title: 'Clonar productos de categoría',
          contentComponent: AddonsNeedUpgradeContent,
          contentComponentProps: {
            id: 'advancedManagement'
          },
          optionsActionsButtons: {
            acceptButtonHide: true,
            cancelButtonText: 'Cerrar'
          },

          visible: true
        })
      }
    },
    /**
     * Redirecciona a la edición de los precios de los productos
     * AdvancedManagement (addon)
     */
    handleModifyProductsPrices() {
      // Dirigimos a la gestión de productos (precios)
      // o al mensaje de "necesitas actualizar"
      if (this.hasAdvancedManagementAddon) {
        this.routerPushTo({
          name: 'advancedManagementProductsPricesForm',
          params: {
            sourceCategory: this.id,
            type: ACTION_TYPE.prices
          }
        })
      } else {
        this.routerPushTo({
          name: 'addonsNeedUpgrade',
          params: {
            id: 'pro'
          }
        })
      }
    },
    /**
     * Get all dishes from a category
     *
     * @param {string} id - parent categorie
     */
    async getEveryNeededData() {
      this.processingRequest = true
      try {
        const category = await getCategoryById(this.id)

        if (!category) {
          throw new Error('No existe la categoría indicada')
        }

        // Set parent category data
        this.parentCategory = await getCategoryById(this.id)
        // Set menu data (Grand parent category)
        this.menuData = await getParentCategoryByChildId(this.id)
        // Set items
        await this.setItemsList()
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Establecemos los elementos del listado
     */
    async setItemsList() {
      try {
        // Loading
        this.processingRequest = true
        // Clean the items
        this.items = []
        // Obtenemos los items (platos) del listado
        this.items = await getEveryDishesByCategoryId(this.id)
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    }
  }
}
