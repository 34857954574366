// Components
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
// Vuex
import { mapGetters } from 'vuex'
// Services
import { updateDishById } from '@/services/dish'
// Utils
import { getPathImage } from '@/utils'
import { get, isNil } from 'lodash'

export default {
  name: 'DishAllergensForm',
  components: { FormButtons },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    // Variables del componente padre "VuetifyTabs"
    index: {
      // Indice que ocupo dentro del componente
      type: Number,
      default: 0
    },
    itemsData: {
      // Datos que se comparten entre pestañas del componente
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      // Form fields
      formFields: {
        allergens: []
      },
      formFieldsValidations: {
        allergens: {}
      },
      dishData: null
    }
  },
  computed: {
    ...mapGetters('config', ['allergensData']),
    /**
     * every allergen options
     *
     * @return {Array}
     */
    everyAllergens() {
      return Object.values(this.allergensData).map((allergen) => {
        return { ...allergen, icon: this.getPathAllergenImage(allergen.icon) }
      })
    },
    /**
     * Campos que son editables en el formulario
     *
     * @return {array | boolean} - listado de los campos editables
     */
    editableFields() {
      const owner = get(this.dishData, 'owner', null)
      const editabled = get(this.dishData, 'editabled', false)
      const fieldsCustomItem = get(this.dishData, 'fieldsCustomItem', [])

      if (!isNil(owner)) {
        return editabled ? fieldsCustomItem : []
      }

      return true // todo es editable
    },
    /**
     * El formulario es editable?
     *
     * @return {boolean}
     */
    formIsEditable() {
      return (
        (Array.isArray(this.editableFields) && this.editableFields.length > 0) ||
        this.editableFields === true
      )
    },
    /**
     * El producto fue creada por una marca?
     *
     * @return {boolean}
     */
    isBrandCategory() {
      return !isNil(get(this.dishData, 'owner', null))
    }
  },
  watch: {
    itemsData: {
      async handler(value) {
        // Los datos que deseamos tomar "normalmente"
        // vienen de la primera pestaña
        this.dishData = get(value, '0.data', {})
        // Establecemos valores del formulario
        this.setFormFieldsValues(get(this.dishData, 'allergens', []))
      },
      immediate: true
    }
  },
  methods: {
    /**
     * When the user must click on cancel button
     */
    handleCancelButton() {
      this.modifyAppDialog({
        visible: false
      })
    },
    /**
     * Establecemos los valores iniciales de los
     * campos del formulario
     *
     * @param {array} allergens - array de alérgenos
     */
    setFormFieldsValues(allergens = []) {
      this.formFields.allergens = allergens
    },
    /**
     * Dado un campo del formulario, comprobamos si este puede
     * ser editado
     *
     * @param {string} name - nombre del campo
     * @return {boolean} - es editable?
     */
    isEditable(name = null) {
      return Array.isArray(this.editableFields)
        ? this.editableFields.indexOf(name) > -1
        : this.editableFields
    },
    /**
     * Get icon to draw allergen
     *
     * @param {string} icon
     * @return {string} path to icon
     */
    getPathAllergenImage(icon) {
      // Remove "img" from it is not neccesary and '"'
      let pathIcon = icon.replace(/\/img\//i, '')
      pathIcon = pathIcon.replace(/"/gim, '')
      return getPathImage(pathIcon)
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Datos a salvar filtrando por los campos editables
      const dataToSave = !Array.isArray(this.editableFields)
        ? this.formFields
        : this.editableFields.reduce((accFields, field) => {
            if (!isNil(this.formFields[field])) {
              accFields[field] = this.formFields[field]
            }
            return accFields
          }, {})

      // Update dish
      await updateDishById({
        id: this.dishData.id,
        // Filtramos los campos editables
        ...dataToSave
      })

      // Modificamos "itemsData" del componente padre (VuetifyTabs)
      this.changeItemsData(
        {
          id: this.id,
          data: dataToSave,
          model: 'dish'
        },
        0
      )

      // Evento general para que cualquier lista
      // a la escucha, recargue su listado
      this.$root.$emit('reload-list')

      // show info
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    },
    /**
     * Modificamos el atributo "itemsData" del componente padre
     *
     * @param {Object} data - datos a emitir
     * @param {Number} index - indice de la pestaña donde almacenar los datos
     */
    changeItemsData(data, index = this.index) {
      this.$parent.$parent.$parent.$emit('onChangeItemsData', index, data)
    }
  },
  // Validations with Vuelidate
  validations: {
    formFields: {
      allergens: {}
    }
  }
}
