// Components
import FormButtons from '@/components/ui/FormButtons'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Services
import { getEveryModifiersByPlaceId } from '@/services/modifier'
// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'DishModifiersListForm',
  components: { FormButtons, VuetifyContentLoading },
  mixins: [uiMixin],
  props: {
    // Datos del plato (producto) que estamos tratando
    dish: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      items: [],
      processingRequest: true,
      selectedItems: []
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    /**
     * Activamos o desactivamos el botón "Aceptar"
     *
     * @return {boolean}
     */
    acceptButtonDisabled() {
      return this.selectedItems.length === 0
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * Cuando pulsamos sobre el botón de aceptar
     */
    handleAcceptButton() {
      this.$parent.changeComponent('relations', {
        ids: this.selectedItems
      })
    },
    /**
     * Cuando pulsamos sobre el botón de cancelar
     */
    handleCancelButton() {
      this.$parent.changeComponent('relations')
    },
    /**
     * Cuando pulsamos sobre el botón de crear
     */
    handleCreateButton() {
      this.$parent.changeComponent('create')
    },
    /**
     * Cuando pulsamos sobe el botón de edición
     *
     * @param {string} id - UID del modificador
     */
    handleEditButton(id) {
      this.$parent.changeComponent('create', { id })
    },
    /**
     * Cargamos todo lo necesario para el componente
     */
    async getEveryNeededData() {
      try {
        this.items = await getEveryModifiersByPlaceId(this.placeData.id, false)
      } catch (error) {
        // Mostramos error
        this.handleError(error.message)
        // Volvemos a las relaciones del producto
        this.$parent.changeComponent('relations')
      } finally {
        this.processingRequest = false
      }
    }
  }
}
