// Request
import { doRequest } from '@/utils/requestApi'

/**
 * Obtiene los modificadores (padres) de un establecimiento
 *
 * @param {string} placeId - UID place en la base de datos
 * @param {boolean} childrens - ¿Obtenemos hijos?
 */
export async function getEveryModifiersByPlaceId(placeId, childrens = true) {
  const result = await doRequest({
    method: 'post',
    url: `v1/modifier/place/${placeId}`,
    data: {
      childrens
    }
  })

  return result.data
}

/**
 * Obtiene los datos de un modificador dado su ID
 *
 * @param {string} id - UID del modificador
 */
export async function getModifierById(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/modifier/${id}`
  })

  return result.data
}

/**
 * Crea o actualiza un "modificador" existente y sus hijos
 *
 * @param {Object} data - datos a salvar
 * @return {Object} data - datos salvados
 */
export const saveModifier = async (data) => {
  const result = await doRequest({
    method: 'post',
    url: 'v1/modifier/save',
    data: {
      ...data
    }
  })

  return result.data
}
/**
 * Eliminamos un modificador de la BD
 *
 * @param {string} id - UID del modificador (metas) en BD
 */
export async function deleteModifierById(id) {
  const result = await doRequest({
    method: 'delete',
    url: `v1/modifier/${id}`
  })

  return result.data
}
/**
 * Eliminamos un item "hijo" de un modificador
 *
 * @param {string} id - UID del item (metaFields) en BD
 * @param {string} modifierId - UID del modificador (metas) en BD
 */
export async function deleteChildModifierById(id, modifierId) {
  const result = await doRequest({
    method: 'delete',
    url: `v1/modifier/child/${id}`,
    data: {
      modifierId
    }
  })

  return result.data
}
