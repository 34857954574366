// Request
import { doRequest } from '@/utils/requestApi'

/**
 * Get "customView" by UID
 *
 * @param {string} id - UID "customView" in database
 */
export async function getCustomViewById(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/custom-view/${id}`
  })

  return result.data
}
