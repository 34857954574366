// Components
import FormButtons from '@/components/ui/FormButtons'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
// Services
import { getEveryModifiersByPlaceId } from '@/services/modifier'
import { updateDishById } from '@/services/dish'
// Vuex
import { mapGetters } from 'vuex'
// utils
import { get, isNil } from 'lodash'

export default {
  name: 'DishModifiersRelationsForm',
  components: { FormButtons, VuetifyContentLoading },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    // Identificadores de modificadores que llegan
    // desde el seleccionador de modificadores
    ids: {
      type: Array,
      default() {
        return []
      }
    },
    dish: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      formFields: {
        modifiers: []
      },
      // Others
      processingRequest: true
    }
  },
  computed: {
    ...mapGetters('place', ['placeData'])
  },
  mounted() {
    this.getEveryNeededData()
  },
  methods: {
    /**
     * Cuando pulsamos en el botón de Cancelar (Cerrar)
     */
    handleCancelButton() {
      this.modifyAppDialog({
        visible: false
      })
    },
    /**
     * Cuando pulsamos sobre el botón de editar modificador
     *
     * @param {String} id - identificador del modificador
     */
    handleEditButton(id) {
      this.$parent.changeComponent('create', { id })
    },
    /**
     * Cuando pulsamos sobre el botón de eliminar modificador
     *
     * @param {String} id - identificador del modificador
     */
    handleDeleteButton(id) {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          // Eliminamos de la lista
          this.formFields.modifiers = this.formFields.modifiers.filter((modifier) => {
            return modifier.id !== id
          })
          // Salvamos (lanzamos el formulario, para salvar)
          this.onSubmit()
        },
        actionButtonText: 'Borrar',
        text: '¿Esta seguro que desea desvincular el modificador?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Cuando pulsamos sobre alguno de los botones de acción
     *
     * @param {String} id - identificador del componente
     */
    handleClickAction(id) {
      this.$parent.changeComponent(id)
    },
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * Cargamos todo lo necesario para el componente
     */
    async getEveryNeededData() {
      try {
        // Inicilizamos los valores del formulario
        await this.setFormFieldsValues()
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Inicializamos los valores de los campos del formulario
     */
    async setFormFieldsValues() {
      // Modificadores del establecimiento
      const placeModifiers = await getEveryModifiersByPlaceId(this.placeData.id)
      // Modificadores del plato (ya existentes)
      const dishModifiers = get(this.dish, 'modifiers', [])

      // Modificadores que hemos seleccionado
      // para incluir en el producto
      if (this.ids && this.ids.length > 0) {
        this.ids.forEach((id) => {
          dishModifiers.push({ id })
        })
      }

      // Establecemos los modificadores del producto
      this.formFields.modifiers = placeModifiers.reduce((sumPlaceModifier, placeModifier) => {
        // Buscamos si el modificador del prodcuto existe en el sistema
        const currentModifier = dishModifiers.find(
          (dishModifier) => dishModifier.id === placeModifier.id
        )

        // Si lo encontramos, completamos con los datos
        if (!isNil(currentModifier)) {
          sumPlaceModifier.push({
            id: placeModifier.id,
            max: !isNil(currentModifier.max) ? currentModifier.max : placeModifier.max,
            min: !isNil(currentModifier.min) ? currentModifier.min : placeModifier.min,
            name: placeModifier.name,
            childs: placeModifier.childs.reduce((sumChildsModifier, childModifier) => {
              // Buscamos si el item (child) existe en el sistema
              const currentChild = !isNil(currentModifier.childs)
                ? currentModifier.childs.find(
                    (placeChildModifier) => placeChildModifier.id === childModifier.id
                  )
                : null

              sumChildsModifier.push({
                id: childModifier.id,
                name: childModifier.name,
                price: !isNil(get(currentChild, 'price', null))
                  ? currentChild.price
                  : childModifier.price
              })

              return sumChildsModifier
            }, [])
          })
        }

        return sumPlaceModifier
      }, [])
    },
    /**
     * Array de elementos a seleccionar para valores mínimos y máximos
     *
     * @param {Object} modifier
     * @param {Boolean} zeroIncluded
     * @return {Array}
     */
    childOptions(modifier, zeroIncluded = true) {
      const childs = modifier.childs.map((child, index) => {
        return index + 1
      })
      return [...(zeroIncluded ? [0] : []), ...childs]
    },
    /**
     * Lanzada cuando pasamos la validación del formulario
     */
    async afterSubmit() {
      // Modifico los modificadores para solo almacenar lo que necesitamos
      const modifiers = this.formFields.modifiers.map((modifier) => {
        return {
          id: modifier.id,
          max: modifier.max,
          min: modifier.min,
          childs: modifier.childs.map((child) => {
            return {
              id: child.id,
              price: child.price
            }
          })
        }
      })

      // Almacenamos cambios
      await updateDishById({
        id: this.dish.id,
        modifiers
      })

      // Almacenamos las modificaciones en el objeto "dish" (itemsData)
      this.updateModifiersInDish(modifiers)

      // Información resultados acción de salvado
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    },
    /**
     * Modificamos el atributo "modifiers" del atributo "itemsData"
     *
     * @param {Object} modifiers - modificadores
     */
    updateModifiersInDish(modifiers) {
      this.$parent.updateItemsData(modifiers)
    }
  },
  // Validations with Vuelidate
  validations: {
    formFields: {
      modifiers: {}
    }
  }
}
