// Contants
import { TRIAL_DAYS } from '@/constants'
// Components
import PlansTrialVersion from '@/components/elements/plans/PlansTrialVersion'
import SectionInformationBox from '@/components/ui/SectionInformationBox'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Vuex
import { mapGetters } from 'vuex'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Services
import { getCustomViewById } from '@/services/customViews'
// Utils
import { isNil } from 'lodash'
import { getPathImage } from '@/utils'

export default {
  name: 'AddonsNeedUpgradeContent',
  components: {
    VuetifyContentLoading,
    SectionInformationBox
  },
  mixins: [uiMixin],
  props: {
    id: {
      required: true,
      type: String,
      default: null
    }
  },
  data() {
    return {
      // Datos por defecto
      customViewData: {
        title: 'Suscríbete y disfruta de esta funcionalidad',
        description:
          'Si estás interesad@ en adquirir esta funcionalidad, pulsa \
          el siguiente botón y te daremos más información sobre esta y \
          otras posibles funcionalidades relacionadas.',
        media: getPathImage('onboarding/setting.svg'),
        plan: 'pro'
      },
      processingRequest: true // loading
    }
  },
  computed: {
    ...mapGetters('company', ['trialPlan']),
    ...mapGetters('place', ['subscribedPackages']),
    /**
     * Etiqueta el botón de acción
     *
     * @return {string}
     */
    buttonLabel() {
      // Tenemos posibilidad de TRIAL?
      const hasTrial = isNil(this.trialPlan) && this.subscribedPackages.length === 0

      return hasTrial ? `Prueba ${TRIAL_DAYS} días ¡gratis!` : 'Contratar Plan'
    },
    /**
     * ¿Está vigente el periodo "TRIAL"?
     *
     * @return {boolean}
     */
    hasTrialVersion() {
      return this.trialPlan && this.trialPlan.daysLeft > -1
    }
  },
  watch: {
    /**
     * Observamos el cambio de ID, ya que al usar la misma ruta
     * para mostrar un contenido diferente, Vue por ahorro de
     * recursos no carga la vista de nuevo, por lo que "trampeamos"
     * haciendo cargar el contenido de esta
     */
    id(value) {
      this.setCustomViewData(value)
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Pulsamos sobre el botón de acción
     */
    handleClickButton() {
      if (isNil(this.trialPlan) && this.subscribedPackages.length === 0) {
        // Modal de trial
        this.modifyAppDialog({
          hideTitle: true,
          contentComponent: PlansTrialVersion,
          contentComponentProps: {
            id: this.customViewData.plan
          },
          hideActionButtons: true,
          visible: true
        })
      } else if (!isNil(this.trialPlan) || this.subscribedPackages.length > 0) {
        // Redirigimos a checkout
        this.routerPushTo({
          path: this.customViewData.plan ? `/checkout/${this.customViewData.plan}` : '/plans'
        })
      }
    },
    /**
     * Obtenemos todos los datos necesarios de la app
     * en la carga inicial
     */
    async getEveryNeededData() {
      // Establecemos los datos de la vista
      await this.setCustomViewData(this.id)
    },
    /**
     * Establecemos los datos del "customView"
     *
     * @param {String} id
     */
    async setCustomViewData(id) {
      if (isNil(id)) return

      try {
        this.processingRequest = true
        // Establecemos los datos de la vista
        const customViewData = await getCustomViewById(id)

        if (!isNil(customViewData)) {
          this.customViewData = customViewData
        }
      } catch (error) {
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.processingRequest = false
      }
    }
  }
}
